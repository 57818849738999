/* eslint-disable */
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { GlobalMetadata } from './metadata.models';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class MetadataService {
  // @ts-ignore
  private globalMetadata: GlobalMetadata;
  private isMetadataSet: any;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: any,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
  ) {
    this.isMetadataSet = {};

    // Applies the Metadata of the Route and otherwise applies the Global one.
    // The 'setTag' function will always override the current Tags.
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd)) // TODO: Change to NavigationEnd when Initialization is finished.
      .subscribe((routeData: any) => {
        let route = this.activatedRoute;
        let hasMetadata = false;

        while (route.children.length > 0) {
          // @ts-ignore
          route = route.firstChild;

          // @ts-ignore
          if (route.snapshot.routeConfig.data) {
            // @ts-ignore
            const metadata = route.snapshot.routeConfig.data['metadata'];

            if (metadata) {
              hasMetadata = true;
              this.updateMetadata(metadata, routeData.url);
            }
          }
        }

        if (!hasMetadata) {
          this.updateMetadata(this.globalMetadata.defaults, routeData.url);
        }
      });
  }

  /**
   * Set Global Meta Metadata.
   *
   * @param {Metadata} metadata
   *
   * @memberOf MetadataService
   */
  setGlobalMetadata(metadata: GlobalMetadata) {
    this.globalMetadata = metadata;
  }

  /**
   * Set Tag.
   * Overwrites the 'Global one'.
   *
   * @param {string} tag
   * @param {string} value
   *
   * @memberOf MetadataService
   */
  setTag(tag: string, value: string): void {
    if (tag === 'title') {
      this.setTitle(value);
    } else {
      value = value
        ? value
        : this.globalMetadata.defaults
          ? this.globalMetadata.defaults[tag]
          : '';

      const tagElement = this.getOrCreateMetaTag(tag);

      tagElement.setAttribute(
        'content',
        tag === 'og:locale' ? value.replace(/-/g, '_') : value,
      );
      this.isMetadataSet[tag] = true;

      if (tag === 'description') {
        const ogDescriptionElement = this.getOrCreateMetaTag('og:description');
        ogDescriptionElement.setAttribute('content', value);
      } else if (tag === 'author') {
        const ogAuthorElement = this.getOrCreateMetaTag('og:author');
        ogAuthorElement.setAttribute('content', value);
      } else if (tag === 'publisher') {
        const ogPublisherElement = this.getOrCreateMetaTag('og:publisher');
        ogPublisherElement.setAttribute('content', value);
      } else if (tag === 'og:locale') {
        const availableLocales = this.globalMetadata.defaults
          ? this.globalMetadata.defaults['og:locale:alternate']
          : '';

        this.updateLocales(value, availableLocales);
        this.isMetadataSet['og:locale:alternate'] = true;
      } else if (tag === 'og:locale:alternate') {
        const ogLocaleElement = this.getOrCreateMetaTag('og:locale');
        const currentLocale = ogLocaleElement.getAttribute('content');

        this.updateLocales(currentLocale, value);
        this.isMetadataSet['og:locale'] = true;
      }
    }
  }

  // @ts-ignore
  private setTitle(title): void {
    const ogTitleElement = this.getOrCreateMetaTag('og:title');

    title =
      title !== undefined
        ? title +
          this.globalMetadata.pageTitleSeparator +
          this.globalMetadata.applicationName
        : this.globalMetadata.applicationName;

    if (!title) {
      console.warn('WARNING: No "page title" specified.');
    }

    ogTitleElement.setAttribute('content', title);
    this.titleService.setTitle(title);
  }

  /**
   * Create Meta Tag.
   *
   * @private
   * @param {string} name
   * @returns {*}
   *
   * @memberOf MetadataService
   */
  private createMetaTag(name: string): any {
    const el = this.document.createElement('meta');
    el.setAttribute(
      name.lastIndexOf('og:', 0) === 0 ? 'property' : 'name',
      name,
    );
    this.document.head.appendChild(el);

    return el;
  }

  /**
   * Get or Create Meta Tag.
   *
   * @private
   * @param {string} name
   * @returns {*}
   *
   * @memberOf MetadataService
   */
  private getOrCreateMetaTag(name: string): any {
    let selector = `meta[name="${name}"]`;

    if (name.lastIndexOf('og:', 0) === 0) {
      selector = `meta[property="${name}"]`;
    }

    let el = this.document.querySelector(selector);

    if (!el) {
      el = this.createMetaTag(name);
    }

    return el;
  }

  /**
   * Update Locales.
   *
   * @private
   * @param {string} currentLocale
   * @param {*} availableLocales
   *
   * @memberOf MetadataService
   */
  private updateLocales(currentLocale: string, availableLocales: any): void {
    if (!currentLocale) {
      currentLocale = this.globalMetadata.defaults
        ? this.globalMetadata.defaults['og:locale']
        : '';
    }

    const html = this.document.querySelector('html');
    html.setAttribute('lang', currentLocale);

    const selector = `meta[property="og:locale:alternate"]`;
    let elements = this.document.querySelectorAll(selector);

    // fixes "TypeError: Object doesn't support property or method 'forEach'" issue on IE11
    elements = Array.prototype.slice.call(elements);

    elements.forEach((el: any) => {
      this.document.head.removeChild(el);
    });

    if (!!currentLocale && !!availableLocales) {
      availableLocales.split(',').forEach((locale: string) => {
        if (currentLocale !== locale) {
          const el = this.createMetaTag('og:locale:alternate');
          el.setAttribute('content', locale.replace(/-/g, '_'));
        }
      });
    }
  }

  /**
   * Update all Metadata with defaults.
   *
   * @private
   * @param {*} metadata
   * @param {string} currentUrl
   * @returns {void}
   *
   * @memberOf MetadataService
   */
  private updateMetadata(metadata: any, currentUrl: string): void {
    if (metadata.disabled) {
      return;
    }

    this.setTitle(metadata.title);

    Object.keys(metadata).forEach((key) => {
      let value = metadata[key];

      if (key === 'title' || key === 'override') {
        return;
      } else if (key === 'og:locale') {
        value = value.replace(/-/g, '_');
      } else if (key === 'og:locale:alternate') {
        const currentLocale = metadata['og:locale'];
        this.updateLocales(currentLocale, metadata[key]);

        return;
      }

      this.setTag(key, value);
    });

    if (this.globalMetadata.defaults) {
      Object.keys(this.globalMetadata.defaults).forEach((key) => {
        // @ts-ignore
        let value = this.globalMetadata.defaults[key];

        if (
          key in this.isMetadataSet ||
          key in metadata ||
          key === 'title' ||
          key === 'override'
        ) {
          return;
        } else if (key === 'og:locale') {
          value = value.replace(/-/g, '_');
        } else if (key === 'og:locale:alternate') {
          const currentLocale = metadata['og:locale'];
          // @ts-ignore
          this.updateLocales(currentLocale, this.globalMetadata.defaults[key]);

          return;
        }

        this.setTag(key, value);
      });
    }

    this.setTag(
      'og:url',
      (this.globalMetadata.applicationUrl || '/') +
        currentUrl.replace(/\/$/g, ''),
    );
  }
}
