import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { MetadataService } from './../metadata/metadata.service';

@Injectable()
export class PageService {
  constructor(
    private http: HttpClient,
    private metadataService: MetadataService,
  ) {}

  /**
   * Get the page and set Meta Metadata.
   *
   * @param {string} id
   * @returns
   *
   * @memberOf PageService
   */
  // eslint-disable-next-line
  get(id: string) {
    // eslint-disable-next-line
    return this.http.get<any>('page/' + id).pipe(
      tap((res) => {
        // Set Metadata.
        this.metadataService.setTag('title', res.title);
        // TODO: Description.
        // TODO: Keywords.
      }),
    );
  }
}
